<template>
  <div :style="$vuetify.breakpoint.smAndDown ? 'padding: 15% 5%' : 'padding: 6% 5%'">
    <PageBreadcrumbs :data="breadcrumbs" />
    <vs-row justify="center">
      <h1>Designer Tops</h1>
      <span class="material-symbols-outlined"> female </span>
    </vs-row>
    <Products :products="tops" />
  </div>
</template>

<script>
export default {
  name: 'Tops',
  components: {
    PageBreadcrumbs: () => import('../components/PageBreadcrumbs.vue'),
    Products: () => import('../components/Products.vue'),
  },
  data: () => ({
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'Tops',
        disabled: true,
      },
    ],
  }),
  metaInfo() {
    return {
      meta: [
        {
        name: 'description',
        content: 'Explore Bengalina’s tops collection, offering a blend of Western and traditional designs. Perfect for any occasion, these tops bring comfort and chic style to your wardrobe.',
        },
        {
          property: 'og:title',
          content: 'Stylish Tops Collection | Bengalina - Dhaka, Bangladesh',
        },
        {
          property: 'og:description',
          content: 'Discover Bengalina’s stylish tops, designed for versatile wear. Choose from Western and traditional styles to elevate your look for any event.',
        },
        {
          name: 'keywords',
          content: 'tops collection Dhaka, Western tops Bangladesh, traditional tops, fashion tops Bengalina, stylish tops, women’s tops Dhaka, latest tops Bangladesh, tops, dresses, bottoms, co-ords, kameez.',
        },
      ]
    }
  },
  computed: {
    tops() {
      return this.$store.state.tops;
    },
  },
};
</script>
